import authStore from "@/store/authStore";
import gammaStore from "@/store/gammaStore";

const archiveRequestTypes = {
    Stream: 0,
    Export: 1,
    Preview: 2,
    Days: 3,
    Hours: 4,
    Keys: 5,
    Save: 6,
    Delete: 7, // Was Images
    Retrieve: 8,
    RetrieveHours: 9,
    LargeExport: 10
}

const streamTypes = {
    Video: 0,
    Audio: 1,
    Information: 2,
    Analytic: 3,
    VideoArchive: 4,
    AudioVideo: 5,
    AudioVideoArchive: 6,
    AvSync: 7,
    SdCard: 8
}

function SmartSuiteArchive(device, sourceToken, dataChannel, tenant) {
    this.sourceToken = sourceToken;
    this.dataChannel = dataChannel;
    this.playerId = device ? device.getDeviceId() + sourceToken + dataChannel : null;
    this.gamma = null;
    this.archive = null;
    this.device = device;
    this.streamType = streamTypes.Video;
    this.retention = null;
    this.tenantInfo = {
        _id: tenant ? tenant._id : this.device.getTenantId(),
        storageServer: tenant ? tenant.storageServer || null : this.device.getStorageServer()
    };
}

SmartSuiteArchive.streamTypes = streamTypes;

SmartSuiteArchive.archiveRequestTypes = archiveRequestTypes;

SmartSuiteArchive.prototype.setStreamType = function (streamType) {
    this.streamType = streamType;
}

SmartSuiteArchive.prototype.connect = async function () {
    this.gamma = await gammaStore.dispatch('getArchivehub', this.tenantInfo._id);
}

SmartSuiteArchive.prototype.close = async function () {
    await gammaStore.dispatch('closeArchivehub', this.tenantInfo._id);
    clearTimeout(this.timeoutGetExports);
}

SmartSuiteArchive.prototype.newArchiveRequest = async function (requestType) {
    await this.checkConnection();
    if (this.archive === null || this.archive === undefined) {
        this.archive = await this.findArchive(this.device);
    }
    return {
        StreamType: this.streamType,
        ArchiveRequestType: requestType,
        DeviceStoreId: await this.getStoreName(),
        StreamServer: config.VUE_APP_STREAMSERVER,
        UserId: await authStore.dispatch('getUserName'),
        FrameRate: 15,
        StorageServer: this.device.getStorageServer()
    };
}

SmartSuiteArchive.prototype.getStoreName = async function() {
    await this.checkConnection();
    if (this.archive === null) {
        this.archive = await this.findArchive(this.device);
    } else if (this.archive === undefined) {
        return undefined;
    }
    return this.archive !== null ? this.archive.storeName : null;
}

SmartSuiteArchive.prototype.getDays = async function (callback) {
    await this.checkConnection();
    let archiveRequest = await this.newArchiveRequest(archiveRequestTypes.Days);
    let result = [];
    this.gamma.streamMessage('streamdaysasync', archiveRequest).subscribe({
        next: (item) => {
            let date = new Date(parseInt(item) * 86400 * 1000 + (new Date().getTimezoneOffset() * 60 * 1000));
            result.push({dayCode: item, datetime: date.toLocaleDateString(), unix: date.valueOf()});
        },
        complete: () => {
            callback(result);
        },
        error: () => {
            callback(undefined);
        }
    });
}

SmartSuiteArchive.prototype.getHours = async function (dayCode, callback) {
    await this.checkConnection();
    let archiveRequest = await this.newArchiveRequest(archiveRequestTypes.Hours);
    archiveRequest.DayCode = dayCode.toString();
    let results = [];
    this.gamma.streamMessage('streamhoursasync', archiveRequest).subscribe({
        next: (item) => {
            results.push(item);
        },
        complete: () => {
            callback(results);
        },
        error: () => {
            callback(undefined);
        }
    });
}

SmartSuiteArchive.prototype.getSegments = async function (dayCode, hourCode, callback) {
    await this.checkConnection();
    let archiveRequest = await this.newArchiveRequest(archiveRequestTypes.Hours);
    archiveRequest.DayCode = dayCode.toString();
    archiveRequest.HourCode = hourCode.toString();
    let result = [];
    this.gamma.streamMessage('streamsegmentkeysasync', archiveRequest).subscribe({
        next: (item) => {
            if (item !== '0') {
                result.push(item);
            }
        },
        complete: () => {
            result.sort((a, b) => {
                return (parseInt(a) > parseInt(b) ? 1 : -1);
            });
            callback(result);
        },
        error: () => {
            callback(undefined);
        }
    });
}

SmartSuiteArchive.prototype.getExports = async function(callback, count = 0) {
    await this.checkConnection();
    let response = undefined;
    try {
        response = await this.gamma.sendMessage('GetExportsAsync', this.tenantInfo.storageServer).catch(() => {
            return undefined;
        });
    } catch (e) {}
    if (response !== undefined) {
        callback(response);
    } else if (count === 3) {
        callback([]);
    } else {
        setTimeout(async () => {
            count++;
            await this.getExports(callback, count);
        }, 2000);
    }
}

SmartSuiteArchive.prototype.getExportPageExports = async function(storageServer, callback,  count) {
    await this.checkConnection();
    if (count === undefined) {
        count = 0;
    }
    let response = await this.gamma.sendMessage('GetExportsAsync', storageServer).catch(() => {
        return undefined;
    });
    if (response !== undefined) {
        callback(response);
    } else if (count === 3) {
        callback([]);
    } else {
        this.timeoutGetExports = setTimeout(async () => {
            count++;
            await this.getExportPageExports(storageServer, callback, count);
        }, 2000);
    }
}

SmartSuiteArchive.prototype.getAllExports = async function (callback) {
    await this.checkConnection();
    this.gamma.streamMessage('StreamAllExportsAsync').subscribe({
        next: (item) => {
            callback([item]);
        },
        complete: () => {},
        error: (err) => {
            console.error(err)
            callback([])
        }
    })
}

SmartSuiteArchive.prototype.getKeyFrameImages = async function (requests, callback) {
    await this.checkConnection();
    let request = await this.newArchiveRequest(archiveRequestTypes.Delete);
    let requestId = requests[0].filmstripId
    request.RequestedSegments = requests.filter(segment => {
        return segment.segment !== undefined;
    }).map(segment => (
        {
            DeviceStoreId: request.DeviceStoreId,
            DayCode: segment.dayCode.toString(),
            HourCode: segment.hourCode.toString(),
            RequestedSegment: segment.segment.toString()
        }
    ));
    //need to have name for request first
    this.gamma.streamMessage('StreamFilmstripAsync', request).subscribe({
        next: (item) => {
            if (item !== '0') {
                callback({image: item, id: requestId});
            }
        },
        complete: () => {
            //callback(result);
        },
        error: (err) => {
            //console.warn(err);
            callback(undefined);
        }
    });
}

SmartSuiteArchive.prototype.streamArchiveRequest = async function (requestedSegments, count = 0) {
    await this.checkConnection();
    let request = await this.newArchiveRequest(archiveRequestTypes.Stream);
    request.RequestedSegments = requestedSegments.map(segment => ({
        DeviceStoreId: request.DeviceStoreId,
        DayCode: segment.dayCode.toString(),
        HourCode: segment.hourCode.toString(),
        RequestedSegment: segment.segment.toString()
    }));
    let archiveRequest = null;
    try {
        archiveRequest = await this.gamma.sendMessage('CacheArchiveRequestAsync', request).catch(e => {
            console.error('error streamArchiveRequest', e);
            return undefined;
        });
    } catch (e) {}
    if (archiveRequest !== null && archiveRequest !== undefined) {
        return archiveRequest;
    } else if (count === 3) {
        return undefined;
    } else {
        setTimeout(async () => {
            count++;
            await this.streamArchiveRequest(requestedSegments, count);
        }, 2000);
    }
}

SmartSuiteArchive.prototype.exportArchiveRequest = async function (requestedSegments, exportName, callback) {
    await this.checkConnection();
    let request = await this.newArchiveRequest(archiveRequestTypes.Export);
    request.ExportName = exportName;
    request.IncludeAudio = await this.isAudioStream();
    request.RequestedSegments = requestedSegments.map(segment => ({
        DeviceStoreId: request.DeviceStoreId,
        DayCode: segment.dayCode.toString(),
        HourCode: segment.hourCode.toString(),
        RequestedSegment: segment.segment.toString()
    }));
    let requestId = await this.gamma.sendMessage('CacheArchiveRequestAsync', request);
    this.gamma.sendMessage('requestarchiveexport', requestId).then(() => {
        callback({
            title: 'Success',
            text: "Video is being compiled; you will receive a notification when it is ready",
            style: 'success'
        });
    }).catch(e => {
        console.error('error exportArchiveRequest', e);
    });
}

SmartSuiteArchive.prototype.queueLargeExport = async function (exportName, sources, days, callback) {
    await this.checkConnection();
    let payload = {
        ArchiveRequestType: archiveRequestTypes.LargeExport,
        StreamType: this.streamType,
        IncludeAudio: false,
        UserId: await authStore.dispatch('getUserName'),
        ExportName: exportName,
        Sources: sources.reduce((accumulator, source) => {
            accumulator[source.streamFriendlyName] = source.storageServer;
            return accumulator;
        }, {}),
        Days: days.map(day => Math.floor(day.valueOf() / 86400000).toString())
    };
    let requestId = await this.gamma.sendMessage('CacheArchiveRequestAsync', payload);
    this.gamma.sendMessage('requestarchiveexport', requestId).then(() => {
        callback({
            title: 'Success',
            text: "Video is being compiled; you will receive a notification when it is ready",
            style: 'success'
        });
    }).catch(e => {
        console.error('error exportArchiveRequest', e);
    });
}

SmartSuiteArchive.prototype.exportUpdateListener = async function (callback) {
    await this.checkConnection();
    await gammaStore.dispatch('setArchivehubListener', {
        _id: this.tenantInfo._id, event: 'exportupdate', callback: (event) => {
            callback(event);
        }
    });
}

SmartSuiteArchive.prototype.exportUpdatesListener = async function (callback) {
    await this.checkConnection();
    await gammaStore.dispatch('setArchivehubListener', {
        _id: this.tenantInfo._id, event: 'exportupdates', callback: (event) => {
            callback(event);
        }
    });
}

//this one may need the storage server added
SmartSuiteArchive.prototype.getDeviceStores = async function(storageServer, callback) {
    await this.checkConnection();
    this.gamma.sendMessage('GetDeviceStores', storageServer).then(response => {
        callback(response);
    }).catch(() => {
        console.error('error getDeviceStores, retrying in 5 seconds');
        setTimeout(() => {
            this.getDeviceStores(storageServer, callback);
        },2000);
    });
}

SmartSuiteArchive.prototype.streamAllDeviceStoresAsync = async function(storageServers, callback) {
    await this.checkConnection();
    this.gamma.streamMessage('StreamAllDeviceStoresAsync', storageServers).subscribe({
        next: (item) => {
            callback([item]);
        },
        complete: () => {
            callback("done");
        },
        error: (err) => {
            console.error(err);
            callback([]);
        }
    })
}

SmartSuiteArchive.prototype.deleteArchiveExport = async function(data) {
    await this.checkConnection();
    data.storageServer = this.tenantInfo.storageServer;
    this.gamma.sendMessage('deletearchiveexport', data).catch(e => {
        console.error('error deleteArchiveExport', e);
    });
}

SmartSuiteArchive.prototype.deleteExportPageArchiveExport = async function(data) {
    await this.checkConnection();
    this.gamma.sendMessage('deletearchiveexport', data).catch(e => {
        console.error('error deleteArchiveExport', e);
    });
}

SmartSuiteArchive.prototype.isAudioStream = async function () {
    if (this.archive === null) {
        this.archive = await this.findArchive(this.device);
    }
    if (this.archive !== undefined && this.archive !== -1 && this.archive !== null) {
        return this.archive.storeName.includes('-av');
    } else {
        return false;
    }
}

SmartSuiteArchive.prototype.getStorageServers = async function (callback, count = 0) {
    await this.checkConnection();
    let storageServers = null;
    try {
        storageServers = await this.gamma.sendMessage('GetStorageServers').catch(() => {
            return undefined;
        });
    } catch (e) {}
    if (storageServers !== null) {
        callback(storageServers);
    } else if (count === 3) {
        callback([]);
    } else {
        setTimeout(async () => {
            count++;
            await this.getStorageServers(callback, count);
        }, 2000);
    }
}

SmartSuiteArchive.prototype.setStorageServer = async function (storageServer) {
    this.tenantInfo.storageServer = storageServer;
}

SmartSuiteArchive.prototype.checkConnection = async function () {
    if (this.gamma === null || (!this.gamma.isConnected() && !this.gamma.isConnecting())) {
        await this.connect();
    }
}

SmartSuiteArchive.prototype.getRetention = async function (callback) {
    //this returns a unix time of the earliest day with archived footage
    await this.getDays(days => {
        if (days === undefined) {
            setTimeout(() => {
                this.getRetention(callback);
            }, 3000);
        } else if (days.length > 0) {
            callback(days.reduce((min, day) => day.unix < min ? day.unix : min, Infinity));
        } else {
            callback(null);
        }
    });
}

SmartSuiteArchive.prototype.findArchive = async function (device, count = 0) {
    await this.checkConnection();
    let archiveList = null;
    try{
        archiveList = await this.gamma.sendMessage('GetDeviceStores', this.tenantInfo.storageServer).catch(e => {console.error(e); return null});
    } catch (e) {await this.checkConnection();}
    if (archiveList !== null) {
        let index;
        if (device.archive === true) {  //If the device is not currently recording
            return {storeName: device.getDeviceId()}
        } else {    //if device is currently recording
            let index2 = device.getStreams().findIndex(stream => {
                return (stream.streamType === streamTypes.VideoArchive || stream.streamType === streamTypes.AudioVideoArchive) && this.sourceToken === stream.sourceToken
            });
            if (index2 !== -1) {
                index = await archiveList.findIndex(archive => {
                    return archive.storeName === device.getStreams()[index2].friendlyName.substring(8).toLowerCase();
                });
            } else {
                index = -1;
            }
        }
        if (index !== -1) {
            return archiveList[index];
        } else {
            return null;
        }
    } else if (count === 3) {
        return null;
    } else {
        setTimeout(async () => {
            count++;
            await this.findArchive(device, count);
        }, 2000);
    }
}

export default SmartSuiteArchive;
